var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payments-table"},[_c('v-card',[(_vm.userId || _vm.invoiceId)?_c('v-card-title',{staticClass:"mb-0 pb-1"},[_vm._v(" Payment Details "),_c('v-spacer')],1):_vm._e(),_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isPaymentsRefreshing},on:{"click":_vm.refreshPayments}})],1),_c('v-col',{staticClass:"col-md-6 text-md-right"},[(!_vm.invoiceId)?_c('table-filter',{attrs:{"table-name":'payments',"show-date-filter":true,"show-payment-method-filter":true,"filters":_vm.tableFilters,"default-date-picker-date":_vm.defaultDatePickerDate},on:{"filter":_vm.applyFilters,"filterLabelChange":_vm.onFilterLabelChange}}):_vm._e()],1),_c('v-col',{staticClass:"col-md-3 text-md-right"},[_c('export-button',{attrs:{"title":_vm.exportTitle,"headers":_vm.exportHeaders,"data-endpoint":_vm.constructCompletePaymentsUrl,"data-path":'payments',"orientation":'landscape'}})],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.filteredSearchFilters,"active-filter":_vm.searchColumn},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{staticClass:"clickable-data-table",attrs:{"headers":_vm.filteredHeaders,"items":_vm.payments,"loading":_vm.isPaymentsLoading,"server-items-length":_vm.pagination.totalItems,"options":_vm.options,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.customer.user.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mpesa_payment ? item.mpesa_payment.first_name : item.customer.user.name)+" ")]}},{key:"item.customer.account_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.account_number)+" ")]}},{key:"item.customer.user.phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.mpesa_payment ? item.mpesa_payment.msisdn : item.customer.user.phone_number)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" Ksh "+_vm._s(_vm._f("formatCurrency")(item.amount))+" ")]}},{key:"item.customer.station.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer.station.name)+" ")]}},{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.getChipColor(item.payment_method)}},[_vm._v(" "+_vm._s(item.payment_method)+" ")])]}},{key:"item.payment_for",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_for.map(function (payment) { return payment.description; }).join(', '))+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.payment_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","loading":_vm.viewingPaymentReceiptsLoading[item.id],"disabled":_vm.viewingPaymentReceiptsLoading[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.viewPaymentReceipt(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._b({},'v-icon',attrs,false),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View receipt pdf")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":"","loading":_vm.loadingPaymentReceipts[item.id],"disabled":_vm.loadingPaymentReceipts[item.id]},on:{"click":function($event){$event.stopPropagation();return _vm.downloadPaymentReceipt(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download receipt pdf")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.sendPaymentReceipt(item)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiSend)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Send receipt pdf")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"ma-2":"","text":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.showDeletePaymentDialog(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])}),_c('v-divider')],1),_c('add-payment-dialog',{attrs:{"show-dialog":_vm.showAddPaymentDialog,"user-id":_vm.userId},on:{"close":function($event){return _vm.$emit('close-payment-dialog')},"paymentAdded":_vm.refreshPayments}}),_c('send-payment-receipt-dialog',{attrs:{"show-dialog":_vm.paymentReceiptToSend.showDialog,"email":_vm.paymentReceiptToSend.email,"payment-id":_vm.paymentReceiptToSend.paymentId},on:{"close":function($event){_vm.paymentReceiptToSend.showDialog = false}}}),_c('confirm-dialog',{attrs:{"show-dialog":_vm.showConfirmDeleteDialog,"is-agree-button-loading":_vm.isConfirmDeleteDialogButtonLoading,"title":'Delete payment?',"agree-text":'Delete',"message":'Are you sure you want to delete this payment? This action can not be reversed.'},on:{"cancel":function($event){_vm.showConfirmDeleteDialog = false},"agree":function($event){return _vm.deletePayment()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }