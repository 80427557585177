<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Payment</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="AddPayment()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="mb-2"
                >
                  <CustomerAutocomplete
                    :form-errors="form.errors"
                    :initial-customer-id="userId"
                    :show-dialog="showDialog"
                    @update="updateCustomerId"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.amount"
                    label="Amount"
                    placeholder="e.g 1000"
                    outlined
                    hint="Input numbers only, no commas"
                    :rules="[
                      v => !!v || 'required', v => v > 0 || 'The value must be greater than 0'
                    ]"
                    :error="form.errors.has('amount')"
                    :error-messages="form.errors.get('amount')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="form.payment_method"
                    label="Payment Method"
                    :items="paymentMethods"
                    :error="form.errors.has('payment_method')"
                    :error-messages="form.errors.get('payment_method')"
                    item-text="name"
                    item-value="id"
                    outlined
                    required
                  ></v-select>
                </v-col>
                <v-expand-transition>
                  <v-col
                    v-show="form.payment_method == 4"
                    cols="12"
                  >
                    <v-autocomplete
                      v-model="form.paid_to"
                      label="Paid To"
                      :items="systemUsers"
                      :error="form.errors.has('payment_method')"
                      :error-messages="form.errors.get('payment_method')"
                      item-text="name"
                      item-value="id"
                      outlined
                    ></v-autocomplete>
                  </v-col>
                </v-expand-transition>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.payment_reference"
                    label="Payment reference (Optional)"
                    placeholder="QDC99NCOLX"
                    hint="This is the payment reference from the payment gateway, if any."
                    :error="form.errors.has('payment_reference')"
                    :error-messages="form.errors.get('payment_reference')"
                    persistent-hint
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <date-time-picker
                    :label="'Payment Date'"
                    :default-date-time="new Date().toISOString()"
                    :max-date="new Date().toISOString()"
                    @close="paymentDateMenu = false"
                    @change="updatePaymentDate"
                  />
                  <small
                    v-show="form.errors.has('payment_date')"
                    class="validation-error"
                  >
                    {{ form.errors.get('payment_date') }}
                  </small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import moment from 'moment'
import CustomerAutocomplete from '@/components/partials/CustomerSearchAutocomplete.vue'
import DateTimePicker from '../partials/DateTimePicker.vue'

export default {
  components: {
    CustomerAutocomplete,
    DateTimePicker,
  },
  props: {
    showDialog: {
      type: Boolean,
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    systemUsers: [],
    form: new Form({
      customer_id: '',
      amount: '',
      payment_reference: '',
      payment_method: '',
      payment_date: moment(new Date(), 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss'),
      paid_to: '',
    }),
    action: 'Add',
    paymentMethods: [],
    paymentDateMenu: false,
  }),
  created() {
    this.getPaymentMethods()
    this.getSystemUsers()
  },
  methods: {
    updateCustomerId(newCustomerId) {
      this.form.customer_id = newCustomerId
    },
    closeDialog() {
      this.$emit('close')
      if (this.userId) {
        this.form.amount = ''
        this.form.payment_reference = ''
        this.form.payment_method = ''
        this.form.payment_date = moment(new Date(), 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss')
        this.form.paid_to = ''

        // Keep customer_id as is
      } else {
        // Reset the entire form
        this.$refs.form.reset()
      }
    },
    AddPayment() {
      this.form
        .post('payments')
        .then(() => {
          this.$toast.success('Payment added successfully')
          this.closeDialog()
          this.$emit('paymentAdded')
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    getPaymentMethods() {
      axios.get('payment-methods')
        .then(response => {
          this.paymentMethods = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    getSystemUsers() {
      axios.get('system-users?perPage=all')
        .then(response => {
          this.systemUsers = response.data
        })
        .catch(error => {
          console.error(error)
        })
    },
    updatePaymentDate(newDate) {
      this.form.payment_date = moment(newDate, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss')
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
    min-height: 48px !important;
    height: unset !important;
}
</style>
