<template>
  <div class="payments">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="text-md-right"
      >
        <v-btn
          v-if="can('payment-create')"
          color="primary"
          class="mb-2"
          @click="showAddPaymentDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Payment
        </v-btn>
      </v-col>
    </v-row>
    <payments-table
      :payments-url="'payments'"
      :show-add-payment-dialog="showAddPaymentDialog"
      @close-payment-dialog="showAddPaymentDialog = false"
    />
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import Breadcrumb from '@/components/partials/BreadCrumb.vue'
import PaymentsTable from '@/components/tables/PaymentsTable.vue'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    Breadcrumb,
    PaymentsTable,
  },
  mixins: [
    hasPermission,
  ],
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        { text: 'Payments', disabled: true },
      ],
      icons: {
        mdiPlus,
      },
      showAddPaymentDialog: false,
    }
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown
    },
  },
}
</script>
