<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Send payment receipt</span>
        </v-card-title>
        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="sendPayment"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.email"
                    label="Email Address"
                    placeholder="Enter email address"
                    :rules="emailRules"
                    :error="form.errors.has('email')"
                    :error-messages="form.errors.get('email')"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              Send
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: '',
    },
    paymentId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    form: new Form({
      email: '',
    }),
    emailRules: [
      v => !!v || 'Email is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
  }),
  watch: {
    email(newEmail) {
      this.form.email = newEmail
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.resetValidation()
      this.form.reset()
    },
    sendPayment() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        this.form.busy = true
        this.form.post(`payments/${this.paymentId}/send`)
          .then(response => {
            this.$toast.success('Payment receipt sent successfully')
            this.closeDialog()
            this.$emit('payment-sent', response.data)
          })
          .catch(error => {
            this.$toast.error(error.response.data.message || 'Failed to send payment')
          })
          .finally(() => {
            this.form.busy = false
          })
      }
    },
  },
}
</script>
